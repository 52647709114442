<template>
  <div id="flightBooking" class="Content-Page">
    <div id="namePage" class="wrap-Main">
      <div class="box-S4 flex-between-center">
        <div class="N-Page T-size-36">Flight Booking</div>
        <v-btn
          class="theme-btn-even"
          @click="createBooking()"
          v-show="userdata.r_id == 2"
        >
          <span class="I-create"></span>
          <span>Create Booking</span>
        </v-btn>
      </div>
    </div>
    <div id="calendarDATA" class="wrap-Main">
      <div class="box-S2">
        <CompListFlight
          ref="CompListFlight"
          :DataDateFromCalendar="datadatefromcalender"
          :DataDepartureFrom="dataDepartureFrom"
          :Service="'bookingselect'"
        ></CompListFlight>
      </div>
      <div class="box-S2">
        <calendar
          :Service="'bookingselect'"
          @DataCalender="BindingDataFromCalendar"
        ></calendar>
      </div>
    </div>
    <div id="partBack" class="wrap-Main">
      <div class="line"></div>
      <div class="box-S4">
        <v-btn text class="ColorTheme-S white--text theme-btn" @click="Back()">
          <span class="I-back"></span>
          <span>Back</span>
        </v-btn>
      </div>
    </div>

    <v-dialog v-model="dialogCreateBooking" persistent max-width="400">
      <v-card id="dialog" class="TF type2">
        <div class="b-content">
          <div class="box-S4 flex-between-center">
            <div class="N-Page T-size-24 TF">Create Booking</div>
          </div>
          <div class="B-carddetail B-formMain">
            <div class="b-row">
              <div class="box-S4">
                <v-text-field
                  label="Flight No."
                  :readonly="readonly_eng || readonly_pilot || readonly_shore"
                  v-model="flightno"
                >
                </v-text-field>
              </div>
            </div>
            <div class="b-row">
              <div class="box-S4">
                <v-select
                  class="hideMessage"
                  :items="itemsListCustomer"
                  item-text="o_name"
                  item-value="o_id"
                  label="Customer"
                  v-model="selectCustomer"
                  :readonly="readonly_eng || readonly_pilot || readonly_shore"
                  return-object
                ></v-select>
              </div>
            </div>

            <div class="b-row">
              <div class="box-S2">
                <v-menu
                  :disabled="readonly_eng || readonly_pilot || readonly_shore"
                  v-model="menuselect_depart_date"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      class="hideMessage input-date"
                      v-model="depart_date"
                      label="Departure Date"
                      readonly
                      v-on="on"
                      append-icon="mdi-calendar-range"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="depart_date"
                    :readonly="readonly_eng || readonly_pilot || readonly_shore"
                    @input="menuselect_depart_date = false"
                  ></v-date-picker>
                </v-menu>
              </div>
              <div class="box-S2">
                <v-text-field
                  label="Departure Time"
                  :readonly="readonly_eng || readonly_pilot || readonly_shore"
                  v-model="depart_time"
                  type="time"
                ></v-text-field>
              </div>
            </div>

            <div class="b-row">
              <div class="box-S4">
                <v-combobox
                  class="hideMessage"
                  :items="itemsListBookingType"
                  label="Booking Type"
                  v-model="bookingType"
                  item-text="bt_name"
                  item-value="bt_id"
                  return-object
                  :readonly="readonly_eng || readonly_pilot || readonly_flight"
                ></v-combobox>
              </div>
            </div>

            <div class="b-row">
              <div class="box-S4">
                <v-combobox
                  class="hideMessage"
                  :items="itemsListDeparture"
                  v-model="selectDeparture"
                  item-text="ap_name"
                  item-value="ap_id"
                  label="Departure From"
                  return-object
                  @change="departureFromChange"
                  :readonly="readonly_eng || readonly_pilot || readonly_flight"
                ></v-combobox>
              </div>
            </div>

            <div class="box-btn">
              <v-btn
                class="theme-btn-even btn-cancel Bsize100"
                text
                @click="dialogCreateBooking = false"
              >
                <span class="T-size-18">cancel</span>
              </v-btn>
              <v-btn
                :disabled="buttonSave"
                class="theme-btn-even btn-save Bsize100"
                text
                @click="GotoCreateBooking()"
              >
                <span class="T-size-18">save</span>
              </v-btn>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import feathersClientUOA from "../plugins/feathers-client-uoa";
import calendar from "../components/main/Calendar";
import CompListFlight from "../components/main/CompList";
import { startOfDay, endOfDay, format, addHours } from "date-fns";
import fillerdate from "../utils/filterdate";

var today = new Date();
export default {
  data: () => ({
    userdata: "",
    datadatefromcalender: new Date().toISOString().substr(0, 10),
    dataDepartureFrom: "",

    dialogCreateBooking: false,

    readonly_flight: false,
    readonly_pilot: false,
    readonly_eng: false,
    readonly_shore: false,

    flightno: "",
    itemsListCustomer: [],
    selectCustomer: "",

    itemsListBookingType: [],
    bookingType: "",

    itemsListDeparture: [],
    selectDeparture: "",

    depart_date: format(new Date(), "yyyy-MM-dd"),
    depart_time: "06:00",

    menuselect_depart_date: false,
    menuselect_depart_time: false,
    menuselect_dest_date: false,
    menuselect_dest_time: false,

    buttonSave: false,
  }),
  components: {
    calendar,
    CompListFlight,
  },
  async mounted() {
    this.userdata = await this.$CurrentUser.GetCurrentUser();
    await this.enable_disable();
    this.BindingDataFromCalendar(format(today, "yyyy-MM-dd"));

    await this.selectListCustomer();
    await this.SelectListBookingType();
    await this.renderListDeparture();
  },
  methods: {
    async SelectListBookingType() {
      try {
        var res = await feathersClientUOA.service("bookingtype").find({});
        this.itemsListBookingType = res.data;
        this.bookingType = this.itemsListBookingType[0];
      } catch (error) {
        console.log(
          "SelectListBookingType ไม่สามารถขอข้อมูลจาก server ได้ /nError : " +
            error
        );
      }
    },

    Back() {
      this.$router.go(-1);
    },
    async BindingDataFromCalendar(dataDate) {
      this.datadatefromcalender = dataDate;
      if (dataDate.o_id != undefined) {
        await this.$refs.CompListFlight.renderListFlight(dataDate);
      }
    },

    async SelectListFlightType() {
      try {
        var q = {};
        var res = await feathersClientUOA.service("flighttype").find({});
        this.itemsListFlightType = res.data;
        this.flightType = {
          ft_id: this.itemsListFlightType[0].ft_id,
          ft_name: this.itemsListFlightType[0].ft_name,
        };
      } catch (error) {
        console.log(error);
        alert("Flight 2 ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },

    async GotoCreateBooking() {
      try {
        let date = format(new Date(), "yyyy-MM-dd");
        var querybooking = {};
        querybooking.b_departure_date = {
          $gt: format(startOfDay(new Date(date)), "yyyy-MM-dd HH:mm:ss"),
          $lt: format(endOfDay(new Date(date)), "yyyy-MM-dd HH:mm:ss"),
        };
        var datacountbooking = await feathersClientUOA
          .service("booking")
          .find({ query: querybooking });
        let countbooking = datacountbooking.data.filter(
          (x) => x.b_departure_date.substr(0, 10) == date
        );
        let numref = 0;
        if (countbooking.length < 10) {
          numref = "000" + (parseInt(countbooking.length) + 1);
        } else {
          numref = "00" + (parseInt(countbooking.length) + 1);
        }
        this.ref_id = date.replace("-", "").replace("-", "") + numref;

        var newBookingDefault = {
          c_id: this.userdata.c_id,
          o_id: this.userdata.o_id,
          f_no: this.flightno,
          b_departure_date: this.depart_date + " " + this.depart_time,
          ap_id: this.selectDeparture.ap_id,
          b_submit_date: null,
          b_accept_date: null,
          bs_id: 1,
          bt_id: this.bookingType.bt_id,
          f_id: null,
          ts_id: null,
          b_revise: null,
          cb: this.userdata.FullName,
          cd: format(new Date(), "yyyy-MMM-dd HH:mm:ss"),
          ref_id: this.ref_id,
          b_approve: null,
          b_approve_date: null,
        };

        var res = await feathersClientUOA
          .service("booking")
          .create(newBookingDefault);

        //SEQ LOG
        let properties = { 
          b_id:res.b_id,
          bs_id:1,
          f_no:this.flightno,
          o_id:this.userdata.o_id,
          b_departure_date:this.depart_date + " " + this.depart_time,
          bt_id:this.bookingType.bt_id,
          ap_id:this.selectDeparture.ap_id,
          ap_departairport_name:this.selectDeparture.ap_name
        };
        this.$log.Info('{action} by {username} ({role}) : b_id={b_id}, ap_departairport_name={ap_departairport_name}', { action : "CreateBooking", username: this.userdata.u_username, role:this.userdata.r_name, ...properties });
        this.$router.push({ path: "/BookingDetail/Edit/" + res.b_id });
      } catch (error) {
        //SEQ LOG
        this.$log.Error("CreateBooking, Error : {error}", {error});
        console.log("CreateBooking, Error : {error}" + error);
      }
    },

    async renderListDeparture() {
      try {
        this.itemsListDeparture = [];
        var data = await feathersClientUOA.service("mastercommand").find({});
        var airports = data[0].airport.filter((x) => x.ap_checkin == true);
        airports.forEach((element) => {
          this.itemsListDeparture.push(element);
        });
      } catch (error) {}
      this.selectDepartureFrom();
    },

    async selectDepartureFrom() {
      try {
        let departureChange =
          await this.$LocalStorageService.getDepartureChange();
        if (departureChange == null) {
          this.selectDeparture = {
            ap_id: this.itemsListDeparture[0].ap_id,
            ap_name: this.itemsListDeparture[0].ap_name,
          };
        } else {
          let obj = JSON.parse(departureChange);
          this.selectDeparture = { ap_id: obj.ap_id, ap_name: obj.ap_name };
        }
      } catch (error) {
        console.log(
          "SelectListCustomer ไม่สามารถขอข้อมูลจาก server ได้ /nError : " +
            error
        );
      }
    },

    async selectListCustomer() {
      try {
        const q = {};
        if (this.userdata.r_id == 2) {
          q.o_id = this.userdata.o_id;
        }
        var res = await feathersClientUOA
          .service("customer")
          .find({ query: q });

        res.data.forEach((element) => {
          this.itemsListCustomer.push({
            o_id: element.o_id,
            o_name: element.o_name,
          });
        });

        this.selectCustomer = this.itemsListCustomer[0];
      } catch (error) {
        console.log(
          "SelectListCustomer ไม่สามารถขอข้อมูลจาก server ได้ /nError : " +
            error
        );
      }
    },

    departureFromChange() {
      this.$LocalStorageService.setDepartureChange(
        this.selectDeparture.ap_id,
        this.selectDeparture.ap_name
      );
    },

    async enable_disable() {
      if (this.userdata.r_id == 4) {
        this.readonly_flight = true;
      } else if (this.userdata.r_id == 9) {
        this.readonly_eng = true;
      } else if (this.userdata.r_id == 12) {
        this.readonly_pilot = true;
      } else if (this.userdata.r_id == 2) {
        this.readonly_shore = false;
      }
    },

    async createBooking() {
      await this.renderListDeparture();
      this.dialogCreateBooking = true;
    },
  },
};
</script>
<style>
</style>