<template>
  <!-- Flight Booking -->
  <div class="listEventcalendar">
    <div class="B-carddate">
      <div class="T-size-20">{{ Datadate }}</div>
    </div>
    <div v-if="Service == 'bookingselect'">
      <div class="box-S4" v-for="item in itemsListFlight" :key="item.b_id">
        <div class="B-carddetail" style="border-width: 2px; border-style: solid" :style="[
          itemsListAirport.find((x) => x.ap_id === item.ap_id).ap_name ==
            'VTSH'
            ? { 'border-color': 'black' }
            : { 'border-color': '#f7f7f7;' },
        ]">
          <div class="b-dateTime">
            <div class="date T-size-20 SemiBold">
              <span class="I-calendar"></span>
              {{ item.b_departure_date | yyyyMMdd }}
            </div>
            <div class="time T-size-20 SemiBold">
              <span class="I-time"></span>
              {{ item.b_departure_date | HHmm }}
            </div>
          </div>
          <div class="b-textData">
            <div class="t-owner">
              <span>Departure From :
                {{
                    itemsListAirport.find((x) => x.ap_id === item.ap_id).ap_name
                }}</span>
              <br />
              <span>Route : {{ item.legs }}</span>
              <br />
              <span>Customer : {{ item.customer.o_name }}</span>
              <br />
              <span>Flight No : {{ item.f_no }}</span>
              <br />
              <span>Passenger Out : {{ item.bookingpassenger.filter(x => x.pt_id == 1 && x.retire == '0').length }}</span>
              <br />
              <span>Passenger In : {{ item.bookingpassenger.filter(x => x.pt_id == 2 && x.retire == '0').length }}</span>
            </div>
            <!-- <div class="t-aircraft">
              Aircraft : <span>-</span>
            </div> -->
          </div>
          <div class="line red"></div>
          <div class="b-tagBtn">
            <div class="b-tag">
              <div class="tag T-size-12 tagRefer">
                Ref ID : {{ item.ref_id }}
              </div>
              <div class="tag T-size-12 tagStatus">
                Status : {{ item.bookingstatus.bs_name }}
              </div>
            </div>
            <v-btn class="theme-btn-even" :to="{
              name: 'BookingDetail',
              params: { mode: 'Edit', id: item.b_id },
            }">
              <span>VIEW BOOKING</span>
              <span class="I-arrrowR"></span>
            </v-btn>
          </div>
        </div>
      </div>
    </div>

    <!-- Flight Schedule -->
    <div v-if="Service == 'flightselectcomplist'">
      <div class="box-S4" v-for="item in itemsListFlight" :key="item.f_id">
        <div class="B-carddetail" style="border-width: 2px; border-style: solid" :style="[
          itemsListAirport.find((x) => x.ap_id === item.ap_departairport_id)
            .ap_name == 'VTSH'
            ? { 'border-color': 'black' }
            : { 'border-color': '#f7f7f7;' },
        ]">
          <div class="b-dateTime">
            <div class="date T-size-20 SemiBold">
              <span class="I-calendar"></span>
              {{ item.ap_depart_date | yyyyMMdd }}
              <span v-if="item.f_accept_pilot" class="I-pilot"></span>
            </div>
            <div class="time T-size-20 SemiBold">
              <span class="I-time"></span>
              {{ item.ap_depart_date | HHmm }}
            </div>
          </div>
          <div class="b-textData">
            <div class="t-owner">
              <span>Departure From :
                {{
                    itemsListAirport.find(
                      (x) => x.ap_id === item.ap_departairport_id
                    ).ap_name
                }}</span>
              <br />
              <span>Route : {{ item.route }}</span>
              <br />
              <span>Customer : {{ item.o_name }}</span><br />
              <span v-if="item.f_flight_no == ''">Flight No : -</span>
              <span v-else>Flight No : {{ item.f_flight_no }} [{{ item.ft_name }}]</span>
              <br />
              <div v-show="userdata.r_id != 16">
                <span v-if="item.f_pilot_name == '' || item.f_pilot_name == null">Pilot : -</span>
                <span v-else>Pilot : {{ item.f_pilot_name }}</span>
                <br />
                <span v-if="
                  item.f_copilot_name == '' || item.f_copilot_name == null
                ">Co Pilot : -</span>
                <span v-else>Co Pilot : {{ item.f_copilot_name }}</span>
              </div>
            </div>
            <div v-show="userdata.r_id != 16" class="t-aircraft">
              Aircraft :
              <span v-if="item.ac_name == null">-</span>
              <span v-else>{{ item.ac_name }}</span>
            </div>
          </div>

          <div class="line red"></div>
          <div class="b-tagBtn">
            <div class="b-tag">
              <div class="tag T-size-12 tagRefer">
                Ref ID : {{ item.f_flight_no }}
              </div>
              <div class="tag T-size-12 tagStatusActive">
                Status : {{ item.fs_name.toUpperCase() }}
              </div>
            </div>
            <div>
              <v-btn style="margin-right: 10px" v-show="
                userdata.r_id == 16 ||
                (userdata.r_id == 2 && userdata.o_id == 4)
              " class="theme-btn-even" :to="{
                name: 'MubadalaDetail',
                params: { mode: 'Edit', id: item.f_id },
              }">
                <span>E-FORM Valeura Energy</span>
              </v-btn>
              <v-btn v-show="userdata.r_id != 16" class="theme-btn-even" :to="{
                name: 'FlightDetail',
                params: { mode: 'Edit', id: item.f_id },
              }">
                <span>VIEW FLIGHT</span>
                <span class="I-arrrowR"></span>
              </v-btn>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div v-if="Service == 'transportselect'">
      <div class="box-S4" v-for="item in itemsListFlight" :key="item.ts_id">
        <div class="B-carddetail">
          <div class="b-dateTime">
            <div class="date T-size-20 SemiBold">
              <span class="I-calendar"></span>
              {{ item.ts_date | yyyyMMdd }}
            </div>
            <div class="time T-size-20 SemiBold">
              <span class="I-time"></span>
              {{ item.ts_date | HHmm }}
            </div>
          </div>
          <div class="line red"></div>
          <div class="b-tagBtn">
            <div class="b-tag">
              <div class="t-owner">
                <span>Customer : {{ item.customer[0].o_name }}</span>
              </div>
              <div class="tag T-size-12 tagRefer">
                Ref ID : {{ item.ref_id }}
              </div>
            </div>
            <v-btn class="theme-btn-even" :to="{
              name: 'TransportDetail',
              params: { mode: 'Edit', id: item.ts_id, b_id: item.b_id == null? 0 : item.b_id },
            }">
              <span>VIEW TRANSPORT</span>
              <span class="I-arrrowR"></span>
            </v-btn>
          </div>
        </div>
      </div>
    </div>

    <!-- Flight Report Flight Log -->
    <div v-if="Service == 'flightlogselectcomplist'">
      <div class="box-S4" v-for="item in itemsListFlight" :key="item.f_id">
        <div class="B-carddetail" style="border-width: 2px; border-style: solid" :style="[
          itemsListAirport.find((x) => x.ap_id === item.ap_departairport_id)
            .ap_name == 'VTSH'
            ? { 'border-color': 'black' }
            : { 'border-color': '#f7f7f7;' },
        ]">
          <div class="b-dateTime">
            <div class="date T-size-20 SemiBold">
              <span class="I-calendar"></span>
              {{ item.ap_depart_date | yyyyMMdd }}
            </div>
            <div class="time T-size-20 SemiBold">
              <span class="I-time"></span>
              {{ item.ap_depart_date | HHmm }}
            </div>
          </div>
          <div class="b-textData">
            <div class="t-owner">
              <span>Departure From :
                {{
                    itemsListAirport.find(
                      (x) => x.ap_id === item.ap_departairport_id
                    ).ap_name
                }}</span>
              <br />
              <span>Customer : {{ item.o_name }}</span><br />
              <span v-if="item.f_flight_no == ''">Flight No : -</span>
              <span v-else>Flight No : {{ item.f_flight_no }} [{{ item.ft_name }}]</span>
            </div>
            <div class="t-aircraft">
              Aircraft :
              <!-- <span>HS-UOH [AW139]</span> -->
              <span v-if="item.ac_name == null">-</span>
              <span v-else>{{ item.ac_name }}</span>
            </div>
          </div>
          <div class="line red"></div>
          <div class="b-tagBtn">
            <div class="b-tag">
              <div class="tag T-size-12 tagRefer" v-if="item.ref_id != null">
                Ref ID : {{ item.ref_id }}
              </div>
              <div class="tag T-size-12 tagStatusTakeoff">
                Status : {{ item.fs_name }}
              </div>
            </div>
            <v-btn class="theme-btn-even" :to="{
              name: 'FlightLogDetail',
              params: { mode: 'Edit', id: item.f_id },
            }">
              <span>VIEW FLIGHT REPORT</span>
              <span class="I-arrrowR"></span>
            </v-btn>
          </div>
        </div>
      </div>
    </div>

    <!-- Flight fuelselect -->
    <div v-if="Service == 'fuelselect'">
      <div class="box-S4" v-for="item in itemsListFlight" :key="item.f_id">
        <div class="B-carddetail" style="border-width: 2px; border-style: solid" :style="[
          itemsListAirport.find((x) => x.ap_id === item.ap_departairport_id)
            .ap_name == 'VTSH'
            ? { 'border-color': 'black' }
            : { 'border-color': '#f7f7f7;' },
        ]">
          <div class="b-dateTime">
            <div class="date T-size-20 SemiBold">
              <span class="I-calendar"></span>
              {{ item.ap_depart_date | yyyyMMdd }}
            </div>
            <div class="time T-size-20 SemiBold">
              <span class="I-time"></span>
              {{ item.ap_depart_date | HHmm }}
            </div>
          </div>
          <div class="b-textData">
            <div class="t-owner">
              <!-- <span>CPOC : {{ item.f_flight_no }}</span> -->
              <span>Departure From :
                {{
                    itemsListAirport.find(
                      (x) => x.ap_id === item.ap_departairport_id
                    ).ap_name
                }}</span>
              <br />
              <span>{{ item.customer.o_name }} : {{ item.f_flight_no }}</span>
            </div>
            <div class="t-aircraft">
              Aircraft :
              <span v-if="item.aircrafts == null">-</span>
              <span v-else>{{ item.aircrafts.ac_name }}</span>
            </div>
          </div>
          <div class="line red"></div>
          <div class="b-tagBtn">
            <div class="b-tag">
              <div class="tag T-size-12 tagRefer">
                Ref ID : {{ item.f_flight_no }}
              </div>
            </div>
            <v-btn class="theme-btn-even" :to="{
              name: 'ManageFuel',
              params: { idflight: item.f_id },
            }">
              <span>Fueling</span>
              <span class="I-arrrowR"></span>
            </v-btn>
          </div>
        </div>
      </div>
    </div>

    <div v-if="itemsListFlight.length == 0">No data</div>
  </div>
</template>

<script>
import feathersClientUOA from "../../plugins/feathers-client-uoa";
import fillerdate from "../../utils/filterdate";
import { sl } from "date-fns/locale";
export default {
  data: () => ({
    itemsListFlight: [],
    // Datadate: new Date().toISOString().substring(0, 10),
    Datadate: new Date(),
    //Add
    userdata: "",
    o_id: 0,
    ap_id: 0,
    itemsListAirport: [],
  }),
  props: ["DataDateFromCalendar", "Service"],
  computed: {},
  async mounted() {
    this.SelectListAirport();
    this.Datadate = new Date().toISOString().substr(0, 10);
    // this.Datadate = new Date();
    //Add
    this.userdata = this.$CurrentUser.GetCurrentUser();
  },
  methods: {
    async renderListFlight(calendaObj) {
      //alert("renderListFlight\nService : " + this.Service + "\ncalendaObj : " + JSON.stringify(calendaObj))
      this.Datadate = calendaObj.date;
      let dataDate = calendaObj.date;
      this.o_id = calendaObj.o_id;
      this.ap_id = calendaObj.ap_id;

      const q = {};
      if (dataDate != null) {
        q.month = dataDate.substr(5, 2);
        q.year = dataDate.substr(0, 4);
      }
      q.retire = 0;
      //Add
      q.o_id = this.o_id;
      q.ap_id = this.ap_id;

      this.itemsListFlight = [];

      try {
        var res = await feathersClientUOA
          .service(this.Service)
          .find({ query: q });
        let a = null;
        if (this.Service == "bookingselect") {
          a = res[0].data[0].filter(
            (x) => x.b_departure_date.substr(0, 10) == dataDate
          );
        } else if (this.Service == "flightselectcomplist") {
          a = res[0].data[0].filter(
            (x) => x.ap_depart_date.substr(0, 10) == dataDate
          );
        } else if (this.Service == "transportselect") {
          a = res[0].data[0].filter((x) => x.ts_date.substr(0, 10) == dataDate);
        } else if (this.Service == "flightlogselectcomplist") {
          a = res[0].data[0].filter(
            (x) => x.ap_depart_date.substr(0, 10) == dataDate
          );
        } else if (this.Service == "fuelselect") {
          a = res[0].data[0].filter(
            (x) => x.ap_depart_date.substr(0, 10) == dataDate
          );
        }
        this.itemsListFlight = a;
        await this.ASCOrderbyDepartDate();
      } catch (error) {
        console.log(error);
        //alert("renderListFlight : " + error)
      }
    },

    async SelectListAirport() {
      try {
        var q = {};
        q.retire = "0";
        var res = await feathersClientUOA.service("airports").find({ query: q });
        this.itemsListAirport = res.data;
      } catch (error) {
        console.log(
          "SelectListAirport ไม่สามารถขอข้อมูลจาก server ได้ /nError : " + error
        );
      }
    },

    async ASCOrderbyDepartDate() {
      if (this.itemsListFlight.length > 0) {
        if (this.Service == "bookingselect") {
          this.itemsListFlight.sort(function (a, b) {
            let start = new Date(a.b_departure_date);
            let end = new Date(b.b_departure_date);
            return start - end;
          });
        }
        if (this.Service == "flightselectcomplist") {
          this.itemsListFlight.sort(function (a, b) {
            let start = new Date(a.ap_depart_date);
            let end = new Date(b.ap_depart_date);
            return start - end;
          });
        }
        if (this.Service == "flightlogselectcomplist") {
          this.itemsListFlight.sort(function (a, b) {
            let start = new Date(a.ap_depart_date);
            let end = new Date(b.ap_depart_date);
            return start - end;
          });
        }
        if (this.Service == "fuelselect") {
          this.itemsListFlight.sort(function (a, b) {
            let start = new Date(a.ap_depart_date);
            let end = new Date(b.ap_depart_date);
            return start - end;
          });
        }
        if (this.Service == "transportselect") {
          this.itemsListFlight.sort(function (a, b) {
            // let startDate = a.ts_date.substring(0, 10);
            // let startTime = a.ts_date.substring(11, 16);

            // let endDate = b.ts_date.substring(0, 10);
            // let endTime = b.ts_date.substring(11, 16);

            // let start = new Date(startDate + " " + startTime);
            // let end = new Date(endDate + " " + endTime);

            let start = new Date(a.ts_date);
            let end = new Date(b.ts_date);

            return start - end;
          });
        }
      }
    },
  },
};
</script>

<style>
</style>